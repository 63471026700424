<template>
  <jrv-pick-streamlined
    v-slot="{ jrv, updateSelect }"
    title="Participación"
    nav1="JRV"
    noJrv="Escoja una JRV del desplegable superior para reportar su participación."
    :nombreJrv="nombreJrvDecorados"
  >
    <div v-if="jrv">
      <div class="banner nivel2">
        Reportar participación para la {{ nombreJrv[jrv] }}:
      </div>

      <ion-input
        @focusin="focusin"
        @focusout="focusout"
        @keypress="keypress"  
        
        v-model="partInput"
        
        inputmode="numeric"
      ></ion-input>

      <ion-button
        expand="block"
        class="botonenvio"
        id="botonenvio"
        @click="enviarParticipacion(jrv, updateSelect)"
        >Enviar reporte</ion-button
      >

      <div class="banner nivel2">
        <div v-if="!loading">
          {{
            participacion[jrv]
              ? "Último reporte de participación: " +
                participacion[jrv].participantes +
                " votantes a las " +
                participacion[jrv].hora +
                "."
              : "No se ha reportado aún participación para esta JRV."
          }}
        </div>

        <ion-spinner
          color="primary"
          :style="loading ? '' : 'display: none'"
        ></ion-spinner>
      </div>
    </div>
  </jrv-pick-streamlined>
</template>

<script lang="ts">
import store from "@/modules/adaptedStorage";
import JrvPickStreamlined from "@/components/JrvPickStreamlined.vue";
import { defineComponent } from "vue";
import { IonButton, IonInput, IonSpinner } from "@ionic/vue";

import {
  attach,
  imagesOutline,
  alertCircleOutline,
  checkmarkCircleOutline,
} from "ionicons/icons";

import { municipios } from "@/modules/cyanRegions";
import cyanRequest from "@/modules/cyanRequest";
import ud from "@/modules/cyanUserData";

export default defineComponent({
  name: "ParticipacionJrvStreamlined",
  components: {
    JrvPickStreamlined,
    IonButton,
    IonSpinner,
    IonInput,
  },
  setup() {
    return {
      attach,
      imagesOutline,
      alertCircleOutline,
      checkmarkCircleOutline,
      ud,
    };
  },
  data() {
    return {
      loading: false,
      partInput: "",
    };
  },
  computed: {
    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
    participacion() {
      return ud("participacion");
    },
    incidencias(): any {
      return ud("incidencias", []);
    },
    nombreJrv(): any {
      return ud("nombreJrv");
    },
    nombreJrvDecorados(): any {
      const nj = {} as any;
      const nombres = ud("nombreJrv");
      const participacion = ud("participacion");
      for (const i in nombres) {
        nj[i] =
          nombres[i] +
          " (" +
          (participacion[i]
            ? "reportada a las " + participacion[i].hora
            : "sin reportar") +
          ")";
      }
      return nj;
    },
  },
  methods: {
    focusin: function (event: any) {
      event.target.select();
    },
    focusout: function (event: any) {
      // Validaciones
      let v = event.target.value;
      const vo = v;

      v = v.replace(/[^0-9]/g, "");
      v += "";

      if (v != vo) event.target.value = v;
    },
    keypress: function (event: any) {
      if (event.key !== "Enter") return;
      const e = document.getElementById("botonenvio");
      e && e.click();
      return;
    },
    async enviarParticipacion(jrv: any, updateSelect: any) {
      const _t = this as any;

      if (_t.partInput == "") return;

      const part = parseInt(_t.partInput);
      if (part < 0) return;

      this.loading = true;

      cyanRequest("jrsl/participacion/" + jrv, {
        isModal: false,
        isPost: true,
        params: {
          participantes: part,
        },
        needsAuth: true,
        retries: 3,
      }).then(function (d) {
        if (d.ok) {
          store.commit("patchUserData", d);
          _t.partInput = "";
        }
        _t.loading = false;

        updateSelect();
      });
    },
  },
});
</script>

<style scoped>
.streamlined-button {
  margin: 3em auto 0;
  width: 75%;
  max-width: 250px;
}
.botonenvio {
  margin-bottom: 2em;
}

ion-input {
  margin: 1em auto;
  width: 6em;
  font-size: 200%;

  margin: 1em auto;
  padding: 10px;
  text-align: center;
  border-radius: 5px;
  background: rgb(179, 233, 229);
}
</style>